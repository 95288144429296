/* some colors */
.color-black {
  color: black;
}

.color-white {
  color: white;
}

.bg-black {
  background-color: black;
}

.bg-white {
  background-color: white;
}

/* GENERIC CSS */

.pointer {
  cursor: pointer;
}

.hide {
  display: none;
}

.show {
  display: block;
}

.text-center {
  text-align: center;
}

.text-end {
  text-align: end;
}

/* Flex */
.flex {
  display: flex;
}

.row {
  flex-direction: row;
}

.column {
  flex-direction: column;
}

.justify-start {
  justify-content: flex-start;
}

.justify-center {
  justify-content: center;
}

.justify-end {
  justify-content: flex-end;
}

.justify-between {
  justify-content: space-between;
}
.align-start {
  align-items: flex-start;
}

.align-center {
  align-items: center;
}

.align-end {
  align-items: flex-end;
}

.rounded {
  border-radius: 4px;
}

.rounded-sm {
  border-radius: 2px;
}

.rounded-lg {
  border-radius: 8px;
}

.rounded-circle {
  border-radius: 50%;
}

.rounded-pill {
  border-radius: 50rem;
}

.rounded-0 {
  border-radius: 0;
}

.font-weight-100 {
  font-weight: 100;
}

.font-weight-200 {
  font-weight: 200;
}

.font-weight-300 {
  font-weight: 300;
}

.font-weight-400 {
  font-weight: 400;
}

.font-weight-500 {
  font-weight: 500;
}

.font-weight-600 {
  font-weight: 600;
}

.font-weight-700 {
  font-weight: 700;
}

.font-weight-800 {
  font-weight: 800;
}

.font-weight-900 {
  font-weight: 900;
}

.no-text-decoration {
  text-decoration: none;
}
