@tailwind base;
@tailwind components;
@tailwind utilities;

.regular-text {
  margin: 0px;
  line-height: 1.57143;
  font-size: 0.875rem;
  font-family: 'Public Sans', sans-serif;
  font-weight: 400;
  color: rgb(99, 115, 129);
}

.table-title-value-text {
  font-weight: 600;
  line-height: 1.57143;
  font-size: 0.875rem;
  font-family: 'Public Sans', sans-serif;
  text-decoration: none;
  color: inherit;
  cursor: pointer;
}

.table-title-sub-value-text {
  line-height: 1.57143;
  font-size: 0.875rem;
  font-family: 'Public Sans', sans-serif;
  font-weight: 400;
  color: rgb(145, 158, 171);
}

.basic-text {
  line-height: 1.57143;
  font-size: 0.875rem;
  font-family: 'Public Sans', sans-serif;
  font-weight: 400;
}

.delete-btn {
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  outline: 0px;
  border: 0px;
  margin: 0px;
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
  appearance: none;
  text-decoration: none;
  font-weight: 700;
  line-height: 1.71429;
  font-size: 0.875rem;
  text-transform: unset;
  font-family: 'Public Sans', sans-serif;
  min-width: 64px;
  padding: 6px 12px;
  border-radius: 8px;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  color: rgb(255, 255, 255);
  background-color: rgb(255, 86, 48);
  box-shadow: none;
}

.training-in-progress-popup {
  z-index: 2147483003;
  border: none;
  position: fixed;
  bottom: 20px;
  right: 105px;
  width: 75px;
  height: 75px;
}

.button {
  position: fixed;
  z-index: 2147483003;
  padding: 0 !important;
  margin: 0 !important;
  border: none;
  bottom: 25px;
  right: 80px;
  max-width: 48px;
  width: 48px;
  max-height: 48px;
  height: 48px;
  border-radius: 50%;
  background: #07374d;
  cursor: pointer;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.06), 0 2px 32px 0 rgba(0, 0, 0, 0.16);
  transition: transform 167ms cubic-bezier(0.33, 0, 0, 1);
  box-sizing: content-box;
}

/* Quill CSS */

.ql-toolbar {
  border-radius: 4px 4px 0px 0px;
}

.ql-container {
  border-radius: 0px 0px 4px 4px;
}
